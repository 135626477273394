.ressourcenplanung {
  // *{
  // font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
  // "Helvetica Neue" ,sans-serif;
  // }

  padding: 8px;

  .dark {
    background-color: #515050 !important;
  }

  .darkCell {
    background-color: #6d6c6c;
  }

  .managerSelectionFull {
    display: flex;
    justify-content: space-between;
  }

  .managerSelectionItem {
    float: left;
    padding: 2px;
    margin: 2px;
    width: 80%;
    white-space: nowrap;
    transition-duration: 0.3s;
    transition-property: transform;
    border-radius: 2px;
  }

  .managerSelectionMenu {
    float: right;
    padding-top: 5px;
  }

  .managerSelectionItem:hover {
    transform: translateX(8px);
  }

  .fullTable {
    width: 100%;
    position: relative;
  }

  .ressourceTable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    margin: 2px;
    border-radius: 3px;
    margin-left: 20%;

    th {
      font-weight: normal;
    }
  }

  .resourcePlanPanel {
    margin-bottom: 24px;
    border-bottom: 1px rgb(221, 221, 221) solid;
    padding-bottom: 10px;
  }

  .fixedTableArea {
    display: flex;
    width: 20%;
    position: absolute;
    z-index: 1;
    left: 0;
    margin-left: 4px;
    margin: 2px;
    margin-top: -24px;
  }

  .fixedTableAreaSlim {
    display: flex;
    width: 20%;
    position: absolute;
    z-index: 1;
    left: 0;
    margin-left: 4px;
    margin: 2px;
    margin-top: -14px;
  }

  .fixedTableArea:hover,
  .fixedTableAreaSlim:hover {
    min-width: 20%;
    width: auto;
  }

  .currentProject {
    font-size: unset;
  }

  .currentProjectTitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 16px;
    max-width: 120px;
    overflow: hidden;
  }

  .currentManager {
    font-size: unset;
  }

  .currentManagerTitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 700;
    overflow: hidden;
  }

  .ressourcenManagement {
    margin-bottom: 12px;
  }

  .delToggle {
    float: right;
  }

  .cell {
    padding: 2px;
    min-width: 45px;
    text-align: center;
  }

  .cellTimelineAndWE {
    background: #f3f2f1;
    padding: 8px 0px 8px 10px;
    min-width: 45px;
    cursor: default;
  }

  .cellToday {
    padding: 6px 0 8px 10px;
    min-width: 45px;
    background: #f3f2f1;
    color: #0078d4;
    border-top: 4px solid;
    border-color: #0078d4;
    font-weight: 600;
    box-sizing: border-box;
  }

  .showYear {
    margin: 6px;
    padding-left: 6px;
    border-left: 1px solid #a7a7a7;
    color: #545454;
  }

  .selectedProjectCoin {
    border-radius: 3px;
  }

  .cellBody {
    padding: 2px;
    min-width: 45px;
    text-align: center;
    background: #efefef;
  }

  .cellBlocked {
    padding: 2px;
    min-width: 45px;
    text-align: center;
    background: #f3f2f1;
    cursor: not-allowed;
  }

  .cellAssignment {
    padding: 2px;
    min-width: 45px;
    text-align: center;
    height: 40px;
    cursor: pointer;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
  }

  .cellAssignment:hover {
    background-color: #0078d4;
  }

  .cellAssignmentNotSelected {
    padding: 2px;
    min-width: 45px;
    text-align: center;
    height: 40px;
    cursor: default;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
  }

  .slim {
    height: 28px;
  }

  .addingForms {
    background: none !important;
  }

  .ddMM {
    font-size: 10px;
  }

  .window {
    margin-top: 5px;
  }

  .homeOnLeft {
    float: left;
    display: inline-flex;
    padding: 4px;
  }

  .highlightAssignments {
    padding: 6px 0px 0px 5px;
  }

  .weekSwitchOnRight {
    float: right;
    display: inline-flex;
    margin-right: 8px;
    padding: 4px;
  }

  .loadingSpinnerManagement {
    padding: 5px 15px;
  }

  .tableControlArea {
    display: inline-block;
    width: 100%;
    background: whitesmoke;
    height: 40px;
  }

  .staticNameCellUser {
    background: #f3f2f1;
    box-sizing: border-box;
    padding: 8px;
    width: 60%;
    float: left;
    margin-left: 2px;

    strong {
      font-weight: 600;
      color: #0078d4;
    }
  }

  .staticNameCellUserSlim {
    background: #f3f2f1;
    box-sizing: border-box;
    padding: 2px 8px;
    width: 60%;
    height: 28px;
    float: left;
    margin-left: 2px;

    strong {
      font-weight: 600;
      color: #0078d4;
    }
  }

  thead tr td:first-child {
    border-top-left-radius: 4px;
  }

  .staticNameCellGroup {
    background: #f3f2f1;
    box-sizing: border-box;
    box-sizing: border-box;
    padding: 8px;
    padding: 15px;
    height: 48px;
    width: 39%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;

    strong {
      font-weight: 600;
      color: #0078d4;
    }
  }

  .staticNameCellGroupSlim {
    background: #f3f2f1;
    box-sizing: border-box;
    padding: 4px 2px;
    height: 28px;
    width: 39%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;

    strong {
      font-weight: 600;
      color: #0078d4;
    }
  }

  .showCurrentManager {
    overflow: hidden;
    display: inline-block;
    width: 20%;
    position: absolute;
    z-index: 1;
    left: 0;
    margin: 2px;
    top: 0;
    height: 82px;
  }
  .showCurrentManager:hover {
    min-width: 20%;
    width: auto;
    background: white;
  }

  .darkhover:hover{
    background-color: #6d6c6c;
  }

  .cmTable {
    padding: 5px;
    width: 100%;
    height: 80%;
    text-align: left;
  }

  .iconButtonContextMenu {
    float: right;
    i:last-of-type {
      display: none !important;
    }
  }

  .iconButtonContextMenu:hover,
  .iconButtonContextMenu:active {
    background-color: transparent;
  }

  .slimContextButton {
    margin-top: -4px;
  }

  .workerNameCellString {
    float: left;
    padding: 5px;
    max-width: 75%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .workerNameCellStringSlim {
    float: left;
    max-width: 75%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .workerNameCell {
    display: flex;
    justify-content: space-between;
  }

  .staticCell {
    min-width: 45px;
    background: #f3f2f1;
    padding: 7px 8px;
    box-sizing: border-box;

    strong {
      font-weight: 600;
      color: #0078d4;
    }
  }

  .planPanelButton {
    margin-left: 8px;
  }

  @-webkit-keyframes fearRemove {
    50% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    100% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
  }

  @keyframes fearRemove {
    50% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    100% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
  }

  .delMe {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
  }

  .delMe:hover {
    -webkit-animation-name: fearRemove;
    animation-name: fearRemove;
    -webkit-animation-duration: 0.15s;
    animation-duration: 0.15s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  //PropertyPane initialization

  .initButtonAndLoading {
    display: unset;
  }

  .initLoading {
    margin: 10px;
    display: inline-block;
  }

  .anotherUuid {
    display: unset;
  }

  // If lists not exists

  .calloutOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: 2;
    background-color: #0078d4;

    .info {
      position: absolute;
      left: 50%;
      top: 50%;
      color: black;
      background-color: white;
      padding: 10px 10px 15px 17px;
      transform: translate(-50%, -50%);
    }

    .info span {
      font-weight: bold;
      display: block;
      font-size: 1.5em;
      margin: 5px 5px 15px 1px;
    }
  }

  .selectionMenu {
    width: 100%;
    margin-top: 5px;
  }

  .managerSelection {
    width: 100%;
    display: grid;
    max-height: 450px;
    overflow: auto;
    grid-template-columns: repeat(5, 20%);
  }
}

// custom scrollbar

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.managerFullList {
  max-height: 500px;
  overflow: auto;
}

.projectDropDown {
  margin-top: 20px;
  float: left;
  width: 100%;
}

.inactiveToggle {
  padding: 5px;
}

.managementDropDown {
  float: left;
  width: 85%;
}

.iconButtonContextMenu2 {
  float: right;
  i:last-of-type {
    display: none !important;
  }
}

.removingDialogText {
  strong {
    text-align: center;
  }
  .checkBoxRemoving {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.projectSelection {
  width: 200px;
  max-height: 250px;
  margin: 5px;
}

.singleProject {
  padding: 2px;
  cursor: pointer;
}

.singleProject:hover {
  font-weight: 600;
}

.singleProjectContainer {
  width: 100%;
  display: table;
}

.singleProjectText {
  float: left;
  padding: 4px;

  span {
    display: inline-block;
  }
}

.singleProjectIcon {
  float: right;
  margin-top: -5px;
}

.highlightOff .cellTimelineAndWE,
.highlightOn .cellTimelineAndWE {
  padding: 2px;
  text-align: center;
  cursor: not-allowed;
  background: #f3f2f1;
  height: 40px;
}

.highlightOff td,
.highlightOn td {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.highlightOn td {
  border-color: #0078d4;
}

.highlightOn .staticCell {
  background-color: #0078d4;
  color: #fff;

  strong {
    color: #fff;
  }
}

.menu {
  width: 120px;
  z-index: 970;
  background-color: #fff;
  box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2);
  position: fixed;
  transition: 0.2s display ease-in;
  display: none;

  .menuOptions {
    list-style: none;
    padding: 10px 0;
    z-index: 970;
    margin: 0;

    .menuOptions {
      font-weight: 500;
      z-index: 970;
      font-size: 14px;
      padding: 10px 40px 10px 20px;
      // border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.absenceDate {
  padding-left: 10px;
}

.absenceDeleteButton {
  float: right;
  top: 0;
  right: 0;
  position: absolute;
}

.absenceList {
  margin: 20px 0 20px 0;
}

.userAbsenceListItem {
  display: inline-block;
  width: 100%;
  position: relative;
  background: whitesmoke;
  padding: 5px;
}

.cbToggles {
  margin: 5px;
  margin-left: 25px;
}

button {
  background: grey;
  border: none;

  .next {
    color: green;
  }

  &[disabled="false"]:hover {
    .next {
      color: red;
      animation: move 0.5s;
      animation-iteration-count: 2;
    }
  }
}

@keyframes move {
  from {
    transform: translate(0%);
  }
  50% {
    transform: translate(-40%);
  }
  to {
    transform: transform(0%);
  }
}

/* Mobile Styles */
@media only screen and (max-width: 700px) {
  .managerSelection {
    grid-template-columns: repeat(1, 100%) !important;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 701px) and (max-width: 900px) {
  .managerSelection {
    grid-template-columns: repeat(2, 50%) !important;
  }
}

/* Big Tablet Styles */
@media only screen and (min-width: 901px) and (max-width: 1300px) {
  .managerSelection {
    grid-template-columns: repeat(3, 33%) !important;
  }
}

/* Desktop Styles */
@media only screen and (min-width: 1301px) {
  .managerSelection {
    grid-template-columns: repeat(5, 20%) !important;
  }
}
